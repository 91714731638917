export default {
  intro: {
    title: 'Сервисы для работы с отзывами и вопросами Wildberries и OZON',
    description: `Отвечайте на отзывы и вопросы покупателей маркетплейсов быстро и легко. Боты Sales Area помогут автоматизировать общение с клиентами как начинающим селлерам, так и крупным товарным производителям.`,
    cardsTitle: 'Продукты',
    specialOffer: 'Автоответы + Аналитика =',
    specialOfferPrice: '₽/мес',
    trial: 'Пробный бесплатный период 7 дней',
    cards: {
      autobot: {
        title: 'АВТООТВЕТЫ \n на ОТЗЫВЫ',
      },
      analyser: {
        title: 'АВТООТВЕТЫ \n на ВОПРОСЫ',
      },
      crm: {
        title: ' AI ГЕНЕРАТОР \n ответов',
      },
    },
  },

  update: {
    version: 'Выпущено обновление SalesArea 1.0.11',
    date: '17.10.2023',
    description: `Добавлена возможность выбора категории товара в шаблоне ответа
      и возможность предварительного просмотра ответа на отзыв.`,
  },
  advantages: {
    title: 'Преимущества SalesArea',
    subtitle: `"Не имеет значения, в каком объеме и по каким каналам вы продаете свои товары и услуги,
    для автоматизации продаж вам достаточно будет иметь только SalesArea"`,
    autograph: 'CIO SalesArea',
    cards: {
      system: {
        title: 'Единая система',
        points: ['Продажи на маркетплейсах', 'Корпоративные продажи (классическая CRM)', 'Продажи в своем интернет-магазине'],
      },
      support: {
        title: 'Служба поддержки всегда на связи',
        points: ['Подключение, настройка, помощь при работе в SalesArea24/7'],
      },
      security: {
        title: 'Безопасное подключение',
        points: [
          'Персональная база данных и сайт для каждого пользователя SalesArea',
          'Работа с данными с сайтов маркетплейсов только по официальному API - ключу',
        ],
      },
      settings: {
        title: 'Быстрая и простая настройка',
        points: ['Вы можете работать в SalesArea из любой точки мира, нужен лишь доступ к интернету'],
      },
    },
  },
  guide: {
    title: 'Что нужно для работы в SalesArea',
    signUp: 'Регистрация в SalesArea',
    signUpDescription: `Вы можете зарегистрироваться самостоятельно или воспользоваться помощью нашей службы поддержки.
     Для этого нажмите на кнопку “Оставить заявку”,
     позвоните по номеру +7 952 217 06 05 или обратитесь к нам в мессенджер.`,
    adjustment: 'Настройка персональной SalesArea',
    adjustmentDescription: `Вы можете пользоваться всем доступным функционалом SalesArea,
     а можете выбрать модули, необходимые именно вам: продажи на маркетплейсах,
     CRM для корпоративных продаж, интеграция с вашим интернет-магазином.`,
    signUpSuccess: 'Регистрация успешна',
  },
  survey: {
    title: 'Примите участие в опросе',
    questions: {
      canals: 'По каким каналам вы продаете свои товары и услуги?',
      programs: 'Пользуетесь ли вы программами для управления и аналитики продаж?',
    },
    answers: {
      marketplaces: 'Маркетплейсы',
      corpSells: 'Корпоративные продажи',
      selfShop: 'Свой интернет магазин',
      another: 'Другое',
      yes: 'Да',
      no: 'Нет',
    },
  },
  contacts: {
    title: 'Контакты',
    subtitle: `Остались вопросы?
    Свяжитесь с нами через форму обратной связи, позвоните по телефону или напишите в мессенджере.`,
    phone: '+7 952 2170605',
    email: 'team@sales-area.ru',
  },
};
