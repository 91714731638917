<template>
  <div class="logo">
    <svg width="219" height="54" viewBox="0 0 219 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M74.135 39.4575C71.8995 39.4575 70.0064 39.0675 68.4652 38.2779C66.9241 37.4883 65.6113 36.3753 64.5078 34.9388L67.8659 31.1621C68.9219 32.6081 69.9873 33.5784 71.0623 34.0731C72.1373 34.5678 73.2503 34.8151 74.3919 34.8151C74.9722 34.8151 75.4954 34.739 75.9615 34.5868C76.4277 34.4346 76.7892 34.2158 77.0556 33.9209C77.3219 33.626 77.4551 33.2835 77.4551 32.8745C77.4551 32.551 77.379 32.2561 77.2173 31.9993C77.0555 31.7424 76.8368 31.5141 76.5514 31.3239C76.2755 31.1336 75.952 30.9528 75.581 30.7911C75.2195 30.6294 74.8105 30.4962 74.3729 30.3916C73.9353 30.2869 73.4881 30.1918 73.0315 30.0967C71.7853 29.8398 70.7008 29.4973 69.7876 29.0788C68.8743 28.6602 68.1038 28.156 67.4949 27.5567C66.8766 26.9669 66.4199 26.2819 66.125 25.5209C65.8206 24.7598 65.6684 23.8941 65.6684 22.9238C65.6684 21.5444 66.0584 20.3363 66.848 19.3088C67.6376 18.2719 68.6745 17.4633 69.9683 16.883C71.2621 16.3027 72.6605 16.0078 74.1731 16.0078C76.3706 16.0078 78.14 16.3598 79.4909 17.0543C80.8322 17.7582 81.9072 18.719 82.7063 19.9462L79.2816 23.2758C78.6157 22.4101 77.8546 21.7727 77.008 21.3351C76.1613 20.8975 75.2671 20.6882 74.3443 20.6882C73.7165 20.6882 73.1742 20.7643 72.6986 20.9166C72.2229 21.0688 71.8614 21.2876 71.6141 21.5825C71.3667 21.8774 71.2431 22.2198 71.2431 22.6289C71.2431 22.9714 71.3477 23.2758 71.5475 23.5517C71.7473 23.818 72.0327 24.0559 72.4037 24.2461C72.7652 24.4364 73.1932 24.6076 73.6689 24.7503C74.1446 24.893 74.6392 25.0072 75.1529 25.0928C76.3421 25.3306 77.4075 25.6446 78.3684 26.0251C79.3292 26.4151 80.1473 26.8908 80.8417 27.4615C81.5267 28.0323 82.0594 28.6982 82.4399 29.4688C82.8205 30.2394 83.0012 31.1146 83.0012 32.1039C83.0012 33.6546 82.6207 34.9769 81.8692 36.0804C81.1176 37.1839 80.0712 38.0211 78.7394 38.6014C77.4075 39.1816 75.8759 39.4766 74.1541 39.4766L74.135 39.4575Z"
        fill="white"
      />
      <path
        d="M91.2889 39.4583C90.2139 39.4583 89.234 39.2395 88.3684 38.8115C87.4931 38.3834 86.8082 37.784 86.304 37.023C85.7998 36.262 85.543 35.3582 85.543 34.3213C85.543 33.4366 85.7047 32.666 86.0281 32.0001C86.3516 31.3342 86.8272 30.7729 87.4646 30.3353C88.102 29.8977 88.8916 29.5552 89.8524 29.3364C90.8132 29.1081 91.9262 28.994 93.1915 28.994H98.3951L98.1002 32.257H93.3151C92.8585 32.257 92.459 32.295 92.1165 32.3711C91.774 32.4472 91.4791 32.5614 91.2413 32.7231C91.0035 32.8848 90.8227 33.0751 90.7086 33.2844C90.5944 33.5032 90.5278 33.76 90.5278 34.0549C90.5278 34.3784 90.6134 34.6542 90.7847 34.8921C90.9559 35.1299 91.1842 35.3106 91.4791 35.4438C91.774 35.577 92.107 35.6341 92.497 35.6341C93.0583 35.6341 93.591 35.539 94.0952 35.3582C94.5994 35.1775 95.056 34.9111 95.4556 34.5686C95.8551 34.2262 96.1691 33.8266 96.4069 33.37L97.3106 35.7007C96.854 36.4332 96.3118 37.0801 95.6839 37.6414C95.0465 38.2026 94.3616 38.6402 93.6196 38.9637C92.8775 39.2871 92.0975 39.4488 91.2793 39.4488L91.2889 39.4583ZM95.7505 39.1349V28.6039C95.7505 27.7192 95.5127 27.0723 95.0275 26.6538C94.5423 26.2352 93.8669 26.0259 93.0107 26.0259C92.1545 26.0259 91.422 26.1876 90.7656 26.492C90.1092 26.806 89.4814 27.3197 88.8725 28.0236L85.7713 25.0175C86.8082 23.9616 87.9878 23.1339 89.3292 22.5346C90.661 21.9353 92.1165 21.6309 93.6862 21.6309C95.1321 21.6309 96.3784 21.8497 97.4343 22.2777C98.4903 22.7058 99.3084 23.3622 99.8887 24.247C100.469 25.1317 100.764 26.2542 100.764 27.6051V39.1349H95.76H95.7505Z"
        fill="white"
      />
      <path d="M104.891 39.1349V15.2285H110.094V39.1349H104.891Z" fill="white" />
      <path
        d="M122.554 39.4581C120.661 39.4581 119.006 39.0776 117.598 38.326C116.19 37.5745 115.096 36.5376 114.335 35.2058C113.574 33.8834 113.184 32.3709 113.184 30.668C113.184 29.3362 113.402 28.109 113.83 27.0055C114.259 25.8925 114.867 24.9412 115.657 24.1421C116.447 23.343 117.369 22.7246 118.435 22.287C119.5 21.8494 120.67 21.6211 121.936 21.6211C123.201 21.6211 124.238 21.8399 125.227 22.268C126.217 22.6961 127.082 23.3049 127.815 24.0755C128.547 24.846 129.108 25.7688 129.499 26.8247C129.889 27.8807 130.069 29.0318 130.05 30.278L130.022 31.6954H116.39L115.609 28.7559H125.788L125.237 29.3362V28.6893C125.199 28.1471 125.027 27.6809 124.732 27.2814C124.438 26.8818 124.067 26.5679 123.6 26.3301C123.134 26.0922 122.611 25.9781 122.031 25.9781C121.165 25.9781 120.442 26.1493 119.852 26.4823C119.262 26.8152 118.815 27.3004 118.511 27.9187C118.206 28.5466 118.054 29.3172 118.054 30.2494C118.054 31.1817 118.254 31.9903 118.654 32.7038C119.053 33.4173 119.633 33.9595 120.395 34.3496C121.156 34.7396 122.069 34.9299 123.125 34.9299C123.838 34.9299 124.485 34.8252 125.065 34.6064C125.646 34.3876 126.274 34.0261 126.939 33.5124L129.365 36.9371C128.699 37.4984 127.986 37.9645 127.234 38.3451C126.483 38.7256 125.712 39.0015 124.923 39.1822C124.133 39.363 123.344 39.4581 122.544 39.4581H122.554Z"
        fill="white"
      />
      <path
        d="M139.129 39.4589C137.493 39.4589 136.028 39.1925 134.734 38.6693C133.44 38.146 132.403 37.4135 131.633 36.4908L134.801 33.7795C135.467 34.4455 136.218 34.9401 137.065 35.2541C137.902 35.568 138.701 35.7202 139.453 35.7202C139.728 35.7202 139.976 35.6917 140.195 35.6251C140.413 35.5585 140.594 35.4729 140.756 35.3492C140.918 35.2351 141.041 35.0924 141.127 34.9306C141.212 34.7689 141.26 34.5786 141.26 34.3694C141.26 33.9413 141.079 33.6178 140.708 33.399C140.537 33.2944 140.223 33.1612 139.776 32.9995C139.319 32.8378 138.739 32.676 138.035 32.4953C137.027 32.2384 136.142 31.9245 135.4 31.563C134.658 31.2015 134.049 30.7734 133.573 30.3073C133.145 29.8792 132.812 29.3845 132.584 28.8232C132.356 28.262 132.242 27.6341 132.242 26.9492C132.242 25.8932 132.556 24.9705 133.193 24.1714C133.83 23.3723 134.658 22.7539 135.695 22.3163C136.732 21.8787 137.845 21.6504 139.053 21.6504C140.004 21.6504 140.899 21.755 141.745 21.9738C142.592 22.1926 143.391 22.4875 144.133 22.8776C144.875 23.2676 145.541 23.7242 146.121 24.2665L143.372 27.3392C143.01 26.9777 142.573 26.6448 142.078 26.3498C141.583 26.0549 141.089 25.8266 140.594 25.6364C140.099 25.4556 139.633 25.3605 139.205 25.3605C138.882 25.3605 138.596 25.389 138.349 25.4366C138.102 25.4937 137.892 25.5793 137.731 25.6934C137.569 25.8076 137.445 25.9503 137.36 26.112C137.274 26.2737 137.227 26.4545 137.227 26.6448C137.227 26.8826 137.284 27.0919 137.407 27.2726C137.521 27.4534 137.693 27.6246 137.911 27.7768C138.102 27.8815 138.444 28.0241 138.929 28.1954C139.415 28.3666 140.052 28.5664 140.851 28.7757C141.859 29.0516 142.725 29.375 143.448 29.727C144.171 30.079 144.742 30.4975 145.179 30.9732C145.541 31.3347 145.807 31.7628 145.969 32.2479C146.131 32.7331 146.207 33.2563 146.207 33.8176C146.207 34.9116 145.902 35.8915 145.284 36.7381C144.666 37.5848 143.829 38.2602 142.763 38.7454C141.698 39.2305 140.47 39.4684 139.101 39.4684L139.129 39.4589Z"
        fill="white"
      />
      <path
        d="M147.045 39.134L155.996 16.5215H160.677L169.562 39.134H164.006L159.707 27.6613C159.554 27.2047 159.393 26.7195 159.202 26.2058C159.022 25.6921 158.841 25.1498 158.67 24.5886C158.498 24.0273 158.337 23.4946 158.184 22.9713C158.032 22.4576 157.909 21.9725 157.794 21.5158L158.727 21.4873C158.594 22.0296 158.46 22.5528 158.308 23.0665C158.156 23.5802 157.994 24.1034 157.823 24.6171C157.652 25.1308 157.471 25.6445 157.29 26.1487C157.109 26.6529 156.919 27.1761 156.729 27.7184L152.467 39.1245H147.035L147.045 39.134ZM151.307 34.8056L153.047 30.6008H163.388L165.129 34.8056H151.297H151.307Z"
        fill="white"
      />
      <path
        d="M171.852 39.1352V21.9831H176.798L177.027 27.5768L176.056 26.4828C176.332 25.579 176.77 24.7609 177.35 24.0284C177.93 23.2959 178.625 22.7156 179.433 22.2875C180.242 21.8594 181.089 21.6406 181.973 21.6406C182.364 21.6406 182.725 21.6692 183.058 21.7167C183.391 21.7738 183.686 21.8499 183.943 21.9546L182.554 27.6719C182.316 27.5387 182.002 27.4341 181.602 27.3294C181.203 27.2343 180.784 27.1867 180.356 27.1867C179.881 27.1867 179.443 27.2628 179.043 27.4246C178.644 27.5863 178.301 27.8146 178.007 28.119C177.721 28.4234 177.493 28.7754 177.331 29.1845C177.169 29.5936 177.093 30.0597 177.093 30.5734V39.1352H171.861H171.852Z"
        fill="white"
      />
      <path
        d="M194.046 39.4581C192.153 39.4581 190.498 39.0776 189.09 38.326C187.682 37.5745 186.588 36.5376 185.827 35.2058C185.066 33.8834 184.676 32.3709 184.676 30.668C184.676 29.3362 184.895 28.109 185.323 27.0055C185.751 25.8925 186.36 24.9412 187.149 24.1421C187.939 23.343 188.862 22.7246 189.927 22.287C190.992 21.8494 192.163 21.6211 193.428 21.6211C194.693 21.6211 195.73 21.8399 196.719 22.268C197.709 22.6961 198.574 23.3049 199.307 24.0755C200.039 24.846 200.601 25.7688 200.991 26.8247C201.381 27.8807 201.561 29.0318 201.542 30.278L201.514 31.6954H187.882L187.102 28.7559H197.281L196.729 29.3362V28.6893C196.691 28.1471 196.52 27.6809 196.225 27.2814C195.93 26.8818 195.559 26.5679 195.093 26.3301C194.626 26.0922 194.103 25.9781 193.523 25.9781C192.657 25.9781 191.934 26.1493 191.344 26.4823C190.755 26.8152 190.308 27.3004 190.003 27.9187C189.699 28.5466 189.546 29.3172 189.546 30.2494C189.546 31.1817 189.746 31.9903 190.146 32.7038C190.545 33.4173 191.126 33.9595 191.887 34.3496C192.648 34.7396 193.561 34.9299 194.617 34.9299C195.33 34.9299 195.977 34.8252 196.558 34.6064C197.138 34.3876 197.766 34.0261 198.432 33.5124L200.858 36.9371C200.192 37.4984 199.478 37.9645 198.727 38.3451C197.975 38.7256 197.204 39.0015 196.415 39.1822C195.625 39.363 194.836 39.4581 194.037 39.4581H194.046Z"
        fill="white"
      />
      <path
        d="M209.527 39.4583C208.452 39.4583 207.472 39.2395 206.607 38.8115C205.731 38.3834 205.046 37.784 204.542 37.023C204.038 36.262 203.781 35.3582 203.781 34.3213C203.781 33.4366 203.943 32.666 204.266 32.0001C204.59 31.3342 205.066 30.7729 205.703 30.3353C206.34 29.8977 207.13 29.5552 208.091 29.3364C209.052 29.1081 210.165 28.994 211.43 28.994H216.633L216.339 32.257H211.553C211.097 32.257 210.697 32.295 210.355 32.3711C210.012 32.4472 209.717 32.5614 209.48 32.7231C209.242 32.8848 209.061 33.0751 208.947 33.2844C208.833 33.5032 208.766 33.76 208.766 34.0549C208.766 34.3784 208.852 34.6542 209.023 34.8921C209.194 35.1299 209.423 35.3106 209.717 35.4438C210.012 35.577 210.345 35.6341 210.735 35.6341C211.297 35.6341 211.829 35.539 212.334 35.3582C212.838 35.1775 213.294 34.9111 213.694 34.5686C214.093 34.2262 214.407 33.8266 214.645 33.37L215.549 35.7007C215.092 36.4332 214.55 37.0801 213.922 37.6414C213.285 38.2026 212.6 38.6402 211.858 38.9637C211.116 39.2871 210.336 39.4488 209.518 39.4488L209.527 39.4583ZM213.989 39.1349V28.6039C213.989 27.7192 213.751 27.0723 213.266 26.6538C212.781 26.2352 212.105 26.0259 211.249 26.0259C210.393 26.0259 209.66 26.1876 209.004 26.492C208.348 26.806 207.72 27.3197 207.111 28.0236L204.01 25.0175C205.046 23.9616 206.226 23.1339 207.567 22.5346C208.899 21.9353 210.355 21.6309 211.924 21.6309C213.37 21.6309 214.617 21.8497 215.673 22.2777C216.729 22.7058 217.547 23.3622 218.127 24.247C218.707 25.1317 219.002 26.2542 219.002 27.6051V39.1349H213.998H213.989Z"
        fill="white"
      />
      <g clip-path="url(#clip0_47_194)">
        <path
          d="M43.4996 0H10.2787C4.60191 0 0 4.60191 0 10.2787V43.4996C0 49.1763 4.60191 53.7782 10.2787 53.7782H43.4996C49.1763 53.7782 53.7782 49.1763 53.7782 43.4996V10.2787C53.7782 4.60191 49.1763 0 43.4996 0Z"
          fill="url(#paint0_linear_47_194)"
        />
        <path
          d="M45.6691 10.9022C46.0121 11.7405 45.6642 12.7152 44.6402 13.817L30.1986 29.471C29.0623 28.384 27.4536 27.468 25.1858 27.1895L24.0276 24.503C24.6218 24.2791 25.1439 24.0857 25.5474 23.94C26.5138 23.5924 27.2168 23.1413 27.7267 22.6533L28.6843 24.9381L40.0777 13.3244L23.9144 13.5577L24.5718 15.1261H24.4457C23.4713 15.1546 22.007 15.5307 20.392 16.0695L19.326 13.5966C18.3798 13.5707 17.0317 13.0459 17.0317 11.3024C17.0318 9.87588 18.4057 9.08594 19.4686 9.08594L42.7089 9.86364C44.1995 9.90253 45.2813 9.95425 45.6691 10.9022Z"
          fill="white"
        />
        <path
          d="M26.4418 19.546C26.4029 18.535 25.5733 17.7184 24.5753 17.7184H24.5105C22.2422 17.7832 14.841 20.9199 12.4561 22.255C8.55458 24.4455 5.93631 28.5544 8.1398 32.4818C9.42302 34.7631 11.3932 35.5019 13.6356 35.5019C15.5021 35.5019 17.563 34.9834 19.5591 34.4131C20.7905 34.0761 22.0477 33.7391 23.0976 33.7391C24.1475 33.7391 25.0419 34.0631 25.5733 35.0223C26.6492 36.9407 24.9771 39.2867 22.6958 40.557C20.5053 41.7883 18.5222 42.2161 16.6816 42.2161H16.3187C15.2428 42.2161 14.3744 43.0975 14.4133 44.1733C14.4392 45.0936 15.1391 45.8713 16.0594 45.975C16.4872 46.0268 16.9408 46.0527 17.4334 46.0527C19.3517 46.0527 21.7367 45.5861 24.5882 43.9918C28.7619 41.6587 32.0024 37.2388 29.5267 32.8188C28.2175 30.4727 26.1177 29.695 23.7198 29.695C21.9181 29.695 19.9479 30.1357 18.0037 30.6931C16.526 31.0949 15.191 31.4319 14.1929 31.4319C13.1949 31.4319 12.4431 31.1338 11.9765 30.3042C10.9007 28.3859 12.9745 26.4546 14.3744 25.6769C16.0076 24.7696 23.0458 22.0995 24.6531 21.5162C25.6252 21.1533 26.4807 20.5829 26.4418 19.546Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_47_194" x1="19.95" y1="1.73478" x2="43.3695" y2="59.85" gradientUnits="userSpaceOnUse">
          <stop stop-color="#4986FC" />
          <stop offset="1" stop-color="#16DF29" />
        </linearGradient>
        <clipPath id="clip0_47_194">
          <rect width="53.7782" height="53.7782" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <img src="/img/newYear.gif" class="logo__img" alt="Новогодний декор" />
  </div>
</template>

<style scoped lang="scss">
.logo {
  display: flex;
  align-items: center;
  height: 49px;
  &__img {
    height: 100%;
    object-fit: contain;
  }
}
</style>
